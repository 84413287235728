<template>
  <div class="three-page">
    <!-- 播放器 -->
    <div id="player-content"></div>
    <!-- 渲染画布 -->
    <div id="render-canvas"></div>
    <!-- 热点列表面板 -->
    <div class="hots-list-panel">
      <div class="add-area">
        <el-button type="primary" size="small" @click="addHotsBtn">添加热点</el-button>
      </div>
      <div class="hots-list">
        <div class="item" v-for="item in hotsList" :key="item.id">
          <div class="left">
            <img :src="require('../../../../../assets/img/hots/new_spotd' + item.icon_id + '.gif')"/>
            <div class="title">{{item.title}}</div>
          </div>
          <div class="right">
            <el-button size="mini" type="primary" icon="el-icon-edit" circle @click="editHots(item.m_id)"></el-button>
            <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="delHots(item.m_id)"></el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 添加单个热点抽屉 -->
    <el-drawer class="add-hots-drawer" title="添加热点" :modal="false" :wrapperClosable="false" :visible.sync="addDrawer" direction="rtl" :before-close="addDrawerClose">
      <div class="drawer-content">
        <el-form ref="addHotsForm" :model="addHotsForm" label-width="80px">
          <div class="section">
            <div class="section-title">选择热点图标</div>
            <div class="section-content">
              <div class="content-title">图标列表</div>
              <div class="icon-list">
                <div class="icon-item" v-for="item in [1,2,3,4,5,6,7,8,9,10,11]" :key="item" @click="addCss2DHots(item)">
                  <el-image :src="require('../../../../../assets/img/hots/new_spotd' + item + '.gif')" ></el-image>
                </div>
              </div>
              <div class="content-title">图标大小</div>
              <div class="icon-size">
                <el-slider v-model="addHotsForm.icon_size" show-tooltip :step="0.1" :min="0.1" :max="2" @change="sizeChange"></el-slider>
              </div>
            </div>
          </div>
          <div class="section">
            <div class="section-title">选择热点类型</div>
            <div class="section-content">
              <div class="content-title">类型选择</div>
              <div class="icon-type">
                <el-select v-model="addHotsForm.type" placeholder="请选择">
                  <el-option label="机位" :value="1"></el-option>
                  <el-option label="图文" :value="2"></el-option>
                  <el-option label="链接" :value="3"></el-option>
                </el-select>
              </div>
              <div class="content-title">热点标题</div>
              <div class="icon-type">
                <el-input placeholder="请输入热点标题" v-model="addHotsForm.title" clearable @input="titleChange" v-if="addHotsForm.type !== 1"></el-input>
                <el-input placeholder="选择机位后自动填写" v-model="addHotsForm.title" clearable @input="titleChange" v-else readonly></el-input>
              </div>
              <div class="content-title" v-if="addHotsForm.type === 1">机位选择</div>
              <div class="camera-list" v-if="addHotsForm.type === 1">
                <div :class="[ addHotsForm.to_camera === item.m_id ? 'active' : '',  'camera-item']"  v-for="item in this.$store.state.cameraList" :key="item.m_id" :index="item.sort" @click="addSetCamera(item.m_id, item.title)">
                  <el-image :src="item.img"></el-image>
                  <div class="title">{{item.title}}</div>
                </div>
              </div>
              <div class="content-title" v-if="addHotsForm.type === 2">图文内容</div>
              <div class="imgtext" v-if="addHotsForm.type === 2">
                <QuillBar v-model="addHotsForm.rich_text" :contenttext="addHotsForm.rich_text" @inputChange="addRichTextChange"></QuillBar>
              </div>
              <div class="content-title" v-if="addHotsForm.type === 3">链接</div>
              <div class="link" v-if="addHotsForm.type === 3">
                <el-input placeholder="请输入链接内容" v-model="addHotsForm.link" clearable></el-input>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <div class="submit-area">
        <el-button type="primary" plain @click="addHotsSubmit">确认添加</el-button>
        <el-button type="warning" plain @click="cancleAdd">取消添加</el-button>
      </div>
    </el-drawer>
    <!-- 编辑单个热点抽屉 -->
    <el-drawer class="add-hots-drawer" title="编辑热点" :modal="false" :wrapperClosable="false" :visible.sync="editDrawer" direction="rtl" :before-close="editDrawerClose">
      <div class="drawer-content">
        <el-form ref="editHotsForm" :model="editHotsForm" label-width="80px">
          <div class="section">
            <div class="section-title">选择热点图标</div>
            <div class="section-content">
              <div class="content-title">图标列表</div>
              <div class="icon-list">
                <div class="icon-item" v-for="item in [1,2,3,4,5,6,7,8,9,10,11]" :key="item" @click="editCss2DHots(item)">
                  <el-image :src="require('../../../../../assets/img/hots/new_spotd' + item + '.gif')" ></el-image>
                </div>
              </div>
              <div class="content-title">图标大小</div>
              <div class="icon-size">
                <el-slider v-model="editHotsForm.icon_size" show-tooltip :step="0.1" :min="0.1" :max="2" @change="sizeEditChange"></el-slider>
              </div>
            </div>
          </div>
          <div class="section">
            <div class="section-title">选择热点类型</div>
            <div class="section-content">
              <div class="content-title">类型选择</div>
              <div class="icon-type">
                <el-select v-model="editHotsForm.type" placeholder="请选择">
                  <el-option label="机位" :value="1"></el-option>
                  <el-option label="图文" :value="2"></el-option>
                  <el-option label="链接" :value="3"></el-option>
                </el-select>
              </div>
              <div class="content-title">热点标题</div>
              <div class="icon-type">
                <el-input placeholder="请输入热点标题" v-model="editHotsForm.title" clearable @input="titleEditChange" v-if="editHotsForm.type !== 1"></el-input>
                <el-input placeholder="选择机位后自动填写" v-model="editHotsForm.title" clearable @input="titleChange" v-else readonly></el-input>
              </div>
              <div class="content-title" v-if="editHotsForm.type === 1">机位选择</div>
              <div class="camera-list" v-if="editHotsForm.type === 1">
                <div :class="[ editHotsForm.tid_camera === item.m_id ? 'active' : '',  'camera-item']"  v-for="item in this.$store.state.cameraList" :key="item.m_id" :index="item.sort" @click="editSetCamera(item.m_id, item.title)">
                  <el-image :src="item.img"></el-image>
                  <div class="title">{{item.title}}</div>
                </div>
              </div>
              <div class="content-title" v-if="editHotsForm.type === 2">图文内容</div>
              <div class="imgtext" v-if="editHotsForm.type === 2">
                <QuillBar v-model="editHotsForm.rich_text" :contenttext="editHotsForm.rich_text" @inputChange="editRichTextChange"></QuillBar>
              </div>
              <div class="content-title" v-if="editHotsForm.type === 3">链接</div>
              <div class="link" v-if="editHotsForm.type === 3">
                <el-input placeholder="请输入链接内容" v-model="editHotsForm.link" clearable></el-input>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <div class="submit-area">
        <el-button type="primary" plain @click="editHotsSubmit">确认修改</el-button>
        <el-button type="warning" plain @click="cancleEdit">取消修改</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import QuillBar from '@/components/common/QuillBar'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js'
import TWEEN from '@tweenjs/tween.js/dist/tween.esm'
export default {
  name: 'SetHots',
  components: {
    QuillBar
  },
  props: {
    cameraItem: Object
  },
  data () {
    return {
      // 当前激活的镜头m_id
      activeCameraMid: '',
      // 场景
      Scene: null,
      // 相机
      Camera: null,
      // 相机参数
      CameraParams: {
        fov: 78,
        near: 1,
        far: 1000
      },
      // 控制器
      OrbitControls: null,
      // 渲染器
      Renderer: null,
      // CSS2D渲染器
      LabelRenderer: null,
      // 渲染画布
      renderCanvasDom: null,
      // 视频播放器DOM
      playerDom: null,
      // 外部球体
      OutBall: {
        // 球体
        Geometry: null,
        // 贴图
        Texture: null,
        // 材质
        Material: null,
        // 合成材质的球体
        Mesh: null
      },
      // 热点层球体
      HotsLayerBall: {
        // 热点层球体
        HotsLayerGeometry: null,
        // 合成后的热点层球体
        HotsLayerCube: null
      },
      // 播放器
      Player: null,
      // 添加热点抽屉弹窗
      addDrawer: false,
      // 添加热点表单
      addHotsForm: {
        tid_camera: '',
        // 时间戳
        m_id: '',
        // 图标ID
        icon_id: 1,
        // 图标大小
        icon_size: 1,
        // 热点文字
        title: '热点',
        // 热点坐标
        point: {
          x: 0,
          y: 0,
          z: 0
        },
        // 热点类型
        type: 1,
        // 图文
        rich_text: '',
        // 外部链接
        link: '',
        // 跳转的机位m_id
        to_camera: ''
      },
      // 镜头下已添加的热点列表
      hotsList: [],
      editDrawer: false,
      // 编辑热点表单
      editHotsForm: {
        tid_camera: '',
        // 热点m_id
        m_id: '',
        // 图标ID
        icon_id: 0,
        // 图标大小
        icon_size: 1,
        // 热点文字
        title: '热点',
        // 热点坐标
        point: {
          x: 0,
          y: 0,
          z: 0
        },
        // 热点类型
        type: 1,
        // 图文
        rich_text: '',
        // 外部链接
        link: '',
        // 跳转的机位m_id
        to_camera: ''
      }
    }
  },
  created () {
  },
  methods: {
    // 根据传递的场景信息覆盖默认的材质 和 相机焦点
    dataCover (cameraItem) {
      this.addHotsForm.tid_camera = cameraItem.m_id
      this.activeCameraMid = cameraItem.m_id
      // 1.1 根据url判断场景类型(视频，图片，模型)
      const urlType = cameraItem.url.substring(cameraItem.url.lastIndexOf('.') + 1)
      if (urlType === 'jpg' || urlType === 'png') {
        // 1.2 图片类型
        const textureLoaderModel = new this.THREE.TextureLoader()
        textureLoaderModel.setCrossOrigin('Anonymous')
        this.OutBall.Texture = textureLoaderModel.load(cameraItem.url, (texture) => {
          this.OutBall.Material.map = texture
          this.OutBall.Material.map.needsUpdate = true
        })
      } else if (urlType === 'm3u8' || urlType === 'flv' || urlType === 'mp4') {
        // 1.3.1 先初始化播放器并播放视频
        this.playerInit(cameraItem)
        // 1.3.2 视频类型
        this.OutBall.Texture = new this.THREE.VideoTexture(this.playerDom)
        this.OutBall.Texture.image.crossOrigin = ''
        this.OutBall.Material.map = this.OutBall.Texture
        this.OutBall.Material.map.needsUpdate = true
      }
      // 设置相机焦点
      this.Camera.position = new this.THREE.Vector3(cameraItem.visionX, cameraItem.visionY, cameraItem.visionZ)
      this.Camera.updateProjectionMatrix()
      this.OrbitControls.update()
      // 设置场景热点
      this.getHotsList(cameraItem.m_id)
    },
    threeInit () {
      // 渲染画布
      this.renderCanvasDom = document.getElementById('render-canvas')
      // 创建场景
      this.Scene = new this.THREE.Scene()
      // 创建相机
      this.Camera = new this.THREE.PerspectiveCamera(this.CameraParams.fov, this.renderCanvasDom.clientWidth / this.renderCanvasDom.clientHeight, this.CameraParams.near, this.CameraParams.far)
      // 1.创建外部球体和材质
      this.OutBall.Geometry = new this.THREE.SphereGeometry(200, 60, 60)
      // 1.1 缩放球体
      this.OutBall.Geometry.scale(-1, 1, 1)
      // 1.2 外部球体材质
      const textureLoaderModel = new this.THREE.TextureLoader()
      textureLoaderModel.setCrossOrigin('Anonymous')
      this.OutBall.Texture = textureLoaderModel.load(require('../../../../../assets/img/Nosignal.jpg'))
      this.OutBall.Material = new this.THREE.MeshBasicMaterial({ map: this.OutBall.Texture })
      // 1.3 组合材质和球体，添加进入场景
      this.OutBall.Mesh = new this.THREE.Mesh(this.OutBall.Geometry, this.OutBall.Material)
      this.OutBall.Mesh.name = '外部球体'
      this.Scene.add(this.OutBall.Mesh)

      // 2.创建热点层球体和材质
      this.HotsLayerBall.HotsLayerGeometry = new this.THREE.SphereGeometry(180, 60, 60)
      this.HotsLayerBall.HotsLayerGeometry.scale(-1, 1, 1)
      const HotsLayerMaterial = new this.THREE.MeshBasicMaterial({ color: 0x00ff00, visible: false })
      this.HotsLayerBall.HotsLayerCube = new this.THREE.Mesh(this.HotsLayerBall.HotsLayerGeometry, HotsLayerMaterial)
      this.HotsLayerBall.HotsLayerCube.name = '热点层'
      this.Scene.add(this.HotsLayerBall.HotsLayerCube)

      // 3.处理页面自适应缩放和事件监听
      // 3.1添加窗口大小变化函数
      window.addEventListener('resize', this.onWindowResize)

      // 创建渲染器 并渲染画面到画布
      this.Renderer = new this.THREE.WebGLRenderer()
      this.Renderer.setPixelRatio(window.devicePixelRatio)
      this.Renderer.setSize(this.renderCanvasDom.clientWidth, this.renderCanvasDom.clientHeight)
      this.renderCanvasDom.appendChild(this.Renderer.domElement)

      // 创建CSS2D渲染器
      this.LabelRenderer = new CSS2DRenderer()
      this.LabelRenderer.setSize(this.renderCanvasDom.clientWidth, this.renderCanvasDom.clientHeight)
      this.LabelRenderer.domElement.id = 'label-dom'
      this.renderCanvasDom.appendChild(this.LabelRenderer.domElement)

      // 3.2 添加鼠标滚轮监听事件
      this.Renderer.domElement.addEventListener('mousewheel', this.onMouseWheel, false)

      // 测试代码
      // 添加热点拖动监听
      // 4 添加控制器
      this.addOrbitControls()
      // 调用动画渲染
      this.animate()
    },
    // 播放器初始化
    playerInit (cameraItem) {
      // 清空视频播放器的dom
      document.getElementById('player-content').innerHTML = ''
      // 1.1 需要判断链接是否为直播链接
      const urlType = cameraItem.url.substring(cameraItem.url.lastIndexOf('.') + 1)
      this.Player = new this.TcPlayer('player-content', {
        [urlType]: cameraItem.url, // 请替换成实际可用的播放地址
        autoplay: true, // iOS 下 safari 浏览器是不开放这个能力的
        live: urlType !== 'mp4',
        wording: {
          2032: '请求视频失败，请检查网络',
          2048: '请求m3u8文件失败，可能是网络错误或者跨域问题'
        }
      })
      // 获取视频源并播放视频
      const videoDom = document.getElementById('player-content')
      this.playerDom = videoDom.getElementsByTagName('video')[0]
      this.playerDom.crossOrigin = ''
      this.Player.play()
    },
    // 创建控制器
    addOrbitControls () {
      // 创建控制器
      this.OrbitControls = new OrbitControls(this.Camera, this.renderCanvasDom)
      this.OrbitControls.listenToKeyEvents(this.renderCanvasDom)
      // 设置镜头阻尼
      this.OrbitControls.enableDamping = true
      this.OrbitControls.dampingFactor = 0.05
      // 禁止摄像机平移
      this.OrbitControls.enablePan = false
      this.OrbitControls.screenSpacePanning = true
      this.OrbitControls.enableZoom = false
      this.OrbitControls.minDistance = 10
      this.OrbitControls.maxDistance = 200
    },
    // 添加css2d热点图标
    addCss2DHots (iconItem) {
      // 判断是否是更改图标还是新建热点
      if (this.addHotsForm.point.x !== 0 || this.addHotsForm.point.y !== 0) {
        console.log('修改热点图标')
        // 更改图标
        this.addHotsForm.icon_id = iconItem
      } else {
        console.log('新增热点图标')
        // 新建热点
        // 生成随机ID
        const randMid = this.GenNonDuplicateID(3)
        const raycaster = new this.THREE.Raycaster()
        // 获取相机中心点坐标
        const domCenter = new this.THREE.Vector2()
        domCenter.x = 0
        domCenter.y = 0.2
        raycaster.setFromCamera(domCenter, this.Camera)
        const focusPointArr = []
        // 将结果存放到 focusPointArr
        raycaster.intersectObject(this.HotsLayerBall.HotsLayerCube, false, focusPointArr)
        // 将已添加的点加入到 [添加热点表单]
        this.addHotsForm.m_id = randMid
        this.addHotsForm.icon_id = iconItem
        this.addHotsForm.point.x = focusPointArr[0].point.x
        this.addHotsForm.point.y = focusPointArr[0].point.y
        this.addHotsForm.point.z = focusPointArr[0].point.z
        // 组装渲染数据
        this.hotsList.push(this.addHotsForm)
      }
      // 重新渲染热点列表
      this.loopInsterHots()
    },
    // 热点拖拽
    // 监听窗口大小变化
    onWindowResize () {
      this.Camera.aspect = this.renderCanvasDom.clientWidth / this.renderCanvasDom.clientHeight
      this.Camera.updateProjectionMatrix()
      this.Renderer.setSize(this.renderCanvasDom.clientWidth, this.renderCanvasDom.clientHeight)
      if (this.LabelRenderer) {
        this.LabelRenderer.setSize(this.renderCanvasDom.clientWidth, this.renderCanvasDom.clientHeight)
      }
    },
    // 监听鼠标滚轮事件
    onMouseWheel (event) {
      event.stopPropagation()
      if (event.wheelDelta) { // 判断浏览器IE，谷歌滑轮事件
        if (event.wheelDelta > 0) { // 当滑轮向上滚动时
          this.CameraParams.fov -= (this.CameraParams.near < this.CameraParams.fov ? 1 : 0)
        }
        if (event.wheelDelta < 0) { // 当滑轮向下滚动时
          this.CameraParams.fov += (this.CameraParams.fov < this.CameraParams.far ? 1 : 0)
        }
      } else if (event.detail) { // Firefox滑轮事件
        if (event.detail > 0) { // 当滑轮向上滚动时
          this.CameraParams.fov -= 1
        }
        if (event.detail < 0) { // 当滑轮向下滚动时
          this.CameraParams.fov += 1
        }
      }
      // 改变fov值，并更新场景的渲染
      this.Camera.fov = this.CameraParams.fov
      this.Camera.updateProjectionMatrix()
      this.OrbitControls.update()
    },
    // 渲染动画
    animate () {
      requestAnimationFrame(this.animate)
      this.Renderer.render(this.Scene, this.Camera)
      if (this.LabelRenderer) {
        this.LabelRenderer.render(this.Scene, this.Camera)
      }
      // 放在 TWEEN.js未加载完成导致报错
      try {
        TWEEN.update()
      } catch (error) {}
    },
    // 获取项目信息
    async getProjectInfo () {
      const { data: res } = await this.$http.get('/panorama-project', { params: { mid: this.$route.params.project_id } })
      if (res.status === 200) {
        this.$store.commit('updatePanoramaAll', res.data.projectInfo)
        this.$store.commit('updateSceneList', res.data.sceneList)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 生成随机ID
    GenNonDuplicateID (randomLength) {
      let idStr = Date.now().toString(36)
      idStr += Math.random().toString(36).substr(2, randomLength)
      return idStr
    },
    // 循环将已添加热点图标渲染进场景
    loopInsterHots () {
      // 删除已添加的label Html元素
      document.getElementById('label-dom').innerHTML = ''
      // 在three中移出已添加的热点
      this.OutBall.Mesh.remove(this.OutBall.Mesh.getObjectByName('热点组'))
      const group = new this.THREE.Group()
      group.name = '热点组'
      this.hotsList.forEach((value, index, array) => {
        const hotsDiv = document.createElement('div')
        hotsDiv.className = 'hot-label'
        hotsDiv.id = value.m_id
        hotsDiv.draggable = true
        const img = document.createElement('img')
        img.src = require('../../../../../assets/img/hots/new_spotd' + value.icon_id + '.gif')
        img.draggable = false
        img.style.transform = 'scale(' + value.icon_size + ')'
        hotsDiv.appendChild(img)
        const titleDiv = document.createElement('div')
        titleDiv.className = 'hot-title'
        titleDiv.innerText = value.title
        hotsDiv.appendChild(titleDiv)
        const hotsLabel = new CSS2DObject(hotsDiv)
        group.add(hotsLabel)
        hotsLabel.position.set(value.point.x, value.point.y, value.point.z)
        this.OutBall.Mesh.add(group)
        hotsDiv.addEventListener('mousedown', (event) => { this.onHotsMouseDown(event, value.m_id) })
      })
    },
    // 热点拖放
    onHotsMouseDown (event, hotsMid) {
      // 检查这个热点是否为已添加的热点
      const pointIndex = this.hotsList.findIndex((val) => {
        return val.m_id === hotsMid
      })
      if (typeof this.hotsList[pointIndex].id === 'undefined') {
        console.log('新增热点')
      } else {
        // 获取拖放热点的信息
        this.editHotsForm = this.hotsList[pointIndex]
        console.log('编辑热点')
        this.editDrawer = true
      }
      // 移出OrbitControls事件监听
      this.OrbitControls.enabled = false
      const dragDom = document.getElementById(hotsMid)
      // 热点图标拖动事件
      this.renderCanvasDom.onmousemove = (e) => {
        const translates = document.defaultView.getComputedStyle(dragDom, null).transform
        // 解析X轴数值
        const translateX = parseFloat(translates.substring(6).split(',')[4]) + 40
        // 解析Y轴数值
        const tanslateY = parseFloat(translates.substring(6).split(',')[5]) + 40
        // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        const left = e.clientX - (translateX + 80)
        const top = e.clientY - (tanslateY + 60)
        dragDom.style.left = left + 'px'
        dragDom.style.top = top + 'px'
      }
      this.renderCanvasDom.onmouseup = (e) => {
        this.renderCanvasDom.onmousemove = null
        this.renderCanvasDom.onmouseup = null
        this.OrbitControls.enabled = true
        this.reset3dPosition(e, hotsMid)
      }
    },
    // 热点拖放结束后需要从新定位热点对应的三维坐标
    reset3dPosition (event, hostMid) {
      const mouse = new this.THREE.Vector2()
      const raycaster = new this.THREE.Raycaster()
      const getBoundingClientRect = this.renderCanvasDom.getBoundingClientRect()
      mouse.x = ((event.clientX - getBoundingClientRect.left) / this.renderCanvasDom.offsetWidth) * 2 - 1
      mouse.y = -((event.clientY - getBoundingClientRect.top) / this.renderCanvasDom.offsetHeight) * 2 + 1
      // 通过摄像机和鼠标位置更新射线
      raycaster.setFromCamera(mouse, this.Camera)
      // 计算物体和射线的焦点
      // 相交点坐标数组
      let intersectObj = null
      // 计算物体和射线的焦点
      const intersects = raycaster.intersectObjects(this.Scene.children)
      for (let i = 0; i < intersects.length; i++) {
        if (intersects[i].object.name === '热点层') {
          intersectObj = (intersects[i].point)
        }
      }
      // 替换默认热点数组中的坐标
      // 1.查找原热点在已添加数组中的索引
      const pointIndex = this.hotsList.findIndex((val) => {
        return val.m_id === hostMid
      })
      if (intersectObj) {
        this.hotsList[pointIndex].point.x = intersectObj.x
        this.hotsList[pointIndex].point.y = intersectObj.y
        this.hotsList[pointIndex].point.z = intersectObj.z
      }
      // 清除之前画布并重新渲染
      this.loopInsterHots()
    },
    // 获取当前镜头下已添加的热点列表
    async getHotsList (cameraMid) {
      const { data: res } = await this.$http.get('/panorama-get-hots-list', { params: { m_id: cameraMid } })
      if (res.status === 200) {
        this.hotsList = res.data
        // 将热点添加进场景
        await this.loopInsterHots()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 添加热点标题改变
    titleChange (value) {
      // 重新渲染热点列表
      this.loopInsterHots()
    },
    // 添加热点图标大小改变
    sizeChange (value) {
      // 重新渲染热点列表
      this.loopInsterHots()
    },
    // 设置机位m_id
    addSetCamera (mid, title) {
      this.addHotsForm.to_camera = mid
      this.addHotsForm.title = title
      // 重新渲染热点列表
      this.loopInsterHots()
    },
    // 添加表单的富文本变化
    addRichTextChange (value) {
      this.addHotsForm.rich_text = value
    },
    // 取消添加热点
    cancleAdd () {
      this.addHotsForm.tid_camera = ''
      this.addHotsForm.m_id = ''
      this.addHotsForm.icon_id = 1
      this.addHotsForm.icon_size = 1
      this.addHotsForm.title = '热点'
      this.addHotsForm.point.x = 0
      this.addHotsForm.point.y = 0
      this.addHotsForm.point.z = 0
      this.addHotsForm.type = 1
      this.addHotsForm.rich_text = ''
      this.addHotsForm.link = ''
      this.addHotsForm.to_camera = ''
      // 重新获取热点列表
      this.getHotsList(this.activeCameraMid)
      // 关闭弹窗
      this.addDrawer = false
    },
    // 添加热点抽屉弹窗关闭事件
    addDrawerClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.addHotsForm.tid_camera = ''
          this.addHotsForm.m_id = ''
          this.addHotsForm.icon_id = 1
          this.addHotsForm.icon_size = 1
          this.addHotsForm.title = '热点'
          this.addHotsForm.point.x = 0
          this.addHotsForm.point.y = 0
          this.addHotsForm.point.z = 0
          this.addHotsForm.type = 1
          this.addHotsForm.rich_text = ''
          this.addHotsForm.link = ''
          this.addHotsForm.to_camera = ''
          // 重新获取热点列表
          this.getHotsList(this.activeCameraMid)
          done()
        })
        .catch(_ => {})
    },
    // 添加热点
    async addHotsSubmit () {
      if (!this.addHotsForm.to_camera && !this.addHotsForm.link && !this.addHotsForm.rich_text) {
        this.$message.error('请选择跳转镜头或富文本或链接')
        return false
      }
      const { data: res } = await this.$http.post('/panorama-add-hots', this.addHotsForm)
      if (res.status === 200) {
        this.$message.success(res.msg)
        // 更新项目信息
        await this.getProjectInfo()
        this.addHotsForm.tid_camera = ''
        this.addHotsForm.m_id = ''
        this.addHotsForm.icon_id = 1
        this.addHotsForm.icon_size = 1
        this.addHotsForm.title = '热点'
        this.addHotsForm.point.x = 0
        this.addHotsForm.point.y = 0
        this.addHotsForm.point.z = 0
        this.addHotsForm.type = 1
        this.addHotsForm.rich_text = ''
        this.addHotsForm.link = ''
        this.addHotsForm.to_camera = ''
        // 重新获取热点列表
        await this.getHotsList(this.activeCameraMid)
        // 关闭弹窗
        this.addDrawer = false
      } else {
        this.$message.error(res.msg)
      }
    },
    // 关闭修改热点抽屉
    editDrawerClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.$refs.editHotsForm.resetFields()
          // 重新获取热点列表
          this.getHotsList(this.activeCameraMid)
          done()
        })
        .catch(_ => {})
    },
    // 修改css2d热点图标
    editCss2DHots (iconItem) {
      // 更改图标
      this.editHotsForm.icon_id = iconItem
      // 修改hotsList中对应项数据
      const dataIndex = this.hotsList.findIndex((val) => {
        return val.m_id === this.editHotsForm.m_id
      })
      this.hotsList[dataIndex].icon_id = iconItem
      // 重新渲染热点列表
      this.loopInsterHots()
    },
    // 修改热点的图标大小
    sizeEditChange (value) {
      // 修改hotsList中对应项数据
      const dataIndex = this.hotsList.findIndex((val) => {
        return val.m_id === this.editHotsForm.m_id
      })
      this.hotsList[dataIndex].icon_size = value
      // 重新渲染热点列表
      this.loopInsterHots()
    },
    // 修改热点标题
    titleEditChange (value) {
      // 修改hotsList中对应项数据
      const dataIndex = this.hotsList.findIndex((val) => {
        return val.m_id === this.editHotsForm.m_id
      })
      this.hotsList[dataIndex].title = value
      // 重新渲染热点列表
      this.loopInsterHots()
    },
    // 修改热点的跳转机位
    editSetCamera (mid, title) {
      // 修改hotsList中对应项数据
      const dataIndex = this.hotsList.findIndex((val) => {
        return val.m_id === this.editHotsForm.m_id
      })
      this.hotsList[dataIndex].to_camera = mid
      this.hotsList[dataIndex].title = title
      this.editHotsForm.title = title
      this.editHotsForm.to_camera = mid
      // 重新渲染热点列表
      this.loopInsterHots()
    },
    // 提交修改热点表单
    async editHotsSubmit () {
      const { data: res } = await this.$http.post('/panorama-edit-hots', this.editHotsForm)
      if (res.status === 200) {
        this.$message.success(res.msg)
        // 更新项目信息
        await this.getProjectInfo()
        this.$refs.editHotsForm.resetFields()
        // 重新获取热点列表
        await this.getHotsList(this.activeCameraMid)
        // 关闭弹窗
        this.editDrawer = false
      } else {
        this.$message.error(res.msg)
      }
    },
    // 取消修改热点信息
    cancleEdit () {
      this.$refs.editHotsForm.resetFields()
      // 重新获取热点列表
      this.getHotsList(this.activeCameraMid)
      // 关闭弹窗
      this.editDrawer = false
    },
    // 编辑表单富文本变化
    editRichTextChange (value) {
      this.editHotsForm.rich_text = value
    },
    // 点击热点编辑按钮
    editHots (mid) {
      // 检查热点在热点列表中的index
      const pointIndex = this.hotsList.findIndex((val) => {
        return val.m_id === mid
      })
      if (typeof this.hotsList[pointIndex].id === 'undefined') {
        this.$message.error('不存在此热点信息，请刷新页面重新获取')
        return false
      }
      this.editHotsForm = this.hotsList[pointIndex]
      // 将相机焦点对准该热点
      this.animateCamera(this.Camera.position, { x: -this.editHotsForm.point.x, y: -this.editHotsForm.point.y, z: -this.editHotsForm.point.z }, 2000, (e) => { console.log(e) })
      this.Camera.updateProjectionMatrix()
      this.OrbitControls.update()
    },
    animateCamera (oldP, newP, time, callBack) {
      const _this = this
      if (TWEEN) {
        const tween = new TWEEN.Tween({
          x1: oldP.x, // 相机x
          y1: oldP.y, // 相机y
          z1: oldP.z // 相机z
        })
        tween.to(
          {
            x1: newP.x,
            y1: newP.y,
            z1: newP.z
          },
          time
        )
        tween.onUpdate(function () {
          _this.Camera.position.x = this._object.x1
          _this.Camera.position.y = this._object.y1
          _this.Camera.position.z = this._object.z1
          _this.OrbitControls.update()
        })
        tween.onComplete(function () {
          _this.OrbitControls.enabled = true
          callBack && callBack()
        })
        tween.easing(TWEEN.Easing.Cubic.InOut)
        tween.start()
      }
    },
    // 删除某个热点
    async delHots (mid) {
      const { data: res } = await this.$http.delete('/hots-item', { params: { m_id: mid } })
      if (res.status === 200) {
        await this.getHotsList()
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 点击添加热点按钮
    addHotsBtn () {
      this.addCss2DHots(1)
      this.addDrawer = true
    }
  },
  mounted () {
    this.threeInit()
  },
  // 监听父组件cameraItem值是否改变
  watch: {
    cameraItem: { // 深度监听，可监听到对象、数组的变化
      handler (newV, oldV) {
        this.dataCover(newV)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.three-page{
  width: 100%;
  height: 100%;
}
#render-canvas{
  width: 100%;
  height: 100%;
  position: relative;
}
.hots-list-panel{
  position: absolute;
  right: 0;
  top: 5px;
  width: 220px;
  background-color: #1c2438;
  height: calc(100vh - 240px);
  padding: 10px;
  z-index: 100;
}
.add-area{
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px dashed #0a0f15;
}
.drawer-content{
  padding-bottom: 40px;
  position: relative;
}
.section{}
.section-title{
  background-color: #FFFFFF;
  background-image: linear-gradient(to right, #FFFFFF , #1c2438);
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  font-weight: bold;
  font-size: 14px;
}
.section-content{}
.content-title{
  color: rgba(255,255,255,0.4);
  padding: 10px;
  font-size: 13px;
}
.icon-list{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 20px;
}
.icon-item{
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2c3e50;
  margin: 4px;
  cursor: pointer;
}
.icon-item:hover{
  background-color: #2c3e50;
}
.icon-size{
  padding: 0 20px;
}
.icon-type{
  padding: 0 20px;
}
.camera-list{
  padding: 0 20px;
}
.imgtext{
  padding: 0 20px;
}
.link{
  padding: 0 20px;
}
.add-hots-drawer{
  pointer-events: none;
}
.add-hots-drawer /deep/.el-drawer{
  background-color: #1c2438;
  pointer-events: auto;
}
/deep/.el-drawer__body{
  background-color: #1c2438;
  overflow-y: auto;
  overflow-x: hidden;
}
/deep/.el-drawer__body::-webkit-scrollbar-track-piece {
  background-color:#1c2438;
}
/deep/.el-drawer__body::-webkit-scrollbar {
  width:4px;
  height:4px;
}
/deep/.el-drawer__body::-webkit-scrollbar-thumb {
  background-color:#E6A23C;
  background-clip:padding-box;
  min-height:10px;
}
/deep/.el-drawer__body::-webkit-scrollbar-thumb:hover {
  background-color:#E6A23C;
}
/deep/#label-dom{
  position: absolute;
  color: red;
  font-size: 30px;
  top: 0px;
  pointer-events: none;
}
/deep/.hot-label{
  cursor: pointer;
  pointer-events: auto;
  width: auto;
  height: auto;
  border: 5px solid #E6A23C;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/deep/.hot-label img{
  width: 100%;
  height: 100%;
}
/deep/.el-drawer{
  width: 300px!important;
}
/deep/.hot-title{
  font-size: 14px;
  text-align: center;
  background-color: rgba(0,0,0,0.8);
  padding: 4px 8px;
  width: max-content;
  margin-top: 4px;
  color: #FFFFFF;
  border-radius: 4px;
  position: absolute;
  bottom: -34px;
  margin-left: auto;
  margin-right: auto;
}
.camera-list .camera-item{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  background-color: rgba(255,255,255,0.1);
  border-radius: 4px;
  padding-right: 6px;
  cursor: pointer;
}
.camera-list .camera-item .el-image{
  width: 80px;
  height: 80px;
  border-radius: 4px;
}
.camera-list .camera-item .title{
  color: rgba(255,255,255,0.6);
  font-size: 14px;
  margin-left: 6px;
}
.camera-list .camera-item.active{
  background-color: rgba(230,162,60);
}
.camera-list .camera-item.active .title{
  color: #0a0f15;
}
.submit-area{
  position: sticky;
  bottom: 0;
  left: 0;
  padding: 10px;
  text-align: center;
  background-color: rgba(255,255,255,0.6);
}
/dee/.ql-toolbar.ql-snow{
  background-color: rgba(255,255,255,0.8);
}
/deep/.ql-container{
  min-height: 300px;
  background-color: #FFFFFF;
}
.hots-list{
  height: calc(100vh - 320px);
  overflow-y: auto;
}
.hots-list .item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(255,255,255,0.1);
  border-radius: 4px;
  margin-bottom: 6px;
  padding: 6px;
}
.hots-list .item .left{
  text-align: center;
}
.hots-list .item .left img{
  width: 50px;
  height: 50px;
}
.hots-list .item .left .title{
  color: rgba(255,255,255,0.6);
  font-size: 13px;
}
</style>
