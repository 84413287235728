<template>
  <div class="hots-page">
    <div class="render-content">
      <set-hots :camera-item="activeCamera"></set-hots>
    </div>
    <bottom-scene-list @updateCamera="updateCamera"></bottom-scene-list>
  </div>
</template>

<script>
import SetHots from '@/components/apps/panorama/projectItem/three/SetHots'
import BottomSceneList from '@/components/apps/panorama/projectItem/common/BottomSceneList'
import 'default-passive-events'
export default {
  name: 'Hots',
  components: {
    BottomSceneList,
    SetHots
  },
  data () {
    return {
      activeCamera: null
    }
  },
  created () {
  },
  methods: {
    getActiveCamera () {
      // 获取当前显示的场景和机位ID
      if (this.$store.state.sceneList.length > 0) {
        // 查找归属该场景下的镜头
        const activeCameraIndex = this.$store.state.cameraList.findIndex(cameraItem => cameraItem.tid_scene === this.$store.state.sceneList[0].m_id)
        if (activeCameraIndex >= 0) {
          this.activeCamera = this.$store.state.cameraList[activeCameraIndex]
        }
      }
    },
    updateCamera (cameraItem) {
      this.activeCamera = cameraItem
    }
  },
  mounted () {
    // 获取当前激活的镜头
    this.getActiveCamera()
  }
}
</script>

<style scoped>
.render-content{
  width: 100%;
  height: calc(100vh - 60px);
  overflow: hidden;
}
</style>
